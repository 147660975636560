var generic = generic || {};

generic.forms = {
  select: {
    addOption: function(args) {
      if (!args) return;
      var val = args.value;
      var label = args.label || val;
      var opt = '<option value="' + val + '">' + label + '</option>';

      args.menuNode.
        append($('<option></option>').
          attr('value', val).
          text(label));
    },
    setValue: function(args) {
      var idx = 0;
      for (var i = 0, len = args.menuNode[0].options.length; i < len; i++) {
        if (args.value === args.menuNode[0].options[i].value) {
          idx = i;
          break;
        }
      }
      args.menuNode[0].selectedIndex = idx;
    }
  }
};

$("input[name*='PHONE2']").on('input keyup mouseup change', function() {
  var locale = Drupal.settings.ajaxPageState.theme ? Drupal.settings.ajaxPageState.theme : '';
  var localeCode = locale !== '' ? locale.substr(0, 2) : '';
  if (localeCode === 'au' || localeCode === 'nz') {
    var value = $(this).val();
    var pos;
    var countryID = Drupal.settings.country_id || 4;
    var phonecode = value.substr(0, 2);
    var oldCode = '04';
    var newCode = '614';
    var isNZ = parseInt(countryID) === 67 ? 1 : 0;
    if (isNZ) {
      oldCode = '02';
      newCode = '642';
    }
    if (phonecode === oldCode) {
      phonecode = phonecode.replace(oldCode, newCode);
      $(this).val(phonecode);
      setCaretPosition($(this).attr('id'), 3);
    } else if ((pos = phonecode.indexOf('-')) !== -1) {
      phonecode = phonecode.replace(/-/g, '');
      $(this).val(phonecode);
      setCaretPosition($(this).attr('id'), pos);
    }
  }
});
  
function setCaretPosition(elemId, caretPos) {
  var elem = document.getElementById(elemId);
  if (elem != null) {
    if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.move('character', caretPos);
      range.select();
    } else {
      if (elem.selectionStart) {
        elem.focus();
        elem.setSelectionRange(caretPos, caretPos);
      } else {
        elem.focus();
      }
    }
  }
}
